import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '../core/authentication/services/auth.service';
import { AppState } from '../core/state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  isAuthorized,
  isLoggedOut,
  hasAuthError
} from '../core/authentication/state/auth.selectors';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { getAuthentication } from '../core/authentication/state/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  private AUTH_STATE_COOKIE = 'AUTH_STATUS';
  private authFailureCode = '';
  isLoginFailed = false;

  constructor(
    private authService: AuthService,
    private cookie: CookieService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    // initialize the login state (for error messages) for the component
    this.initLoginState();

    if (this.isLoginFailed) {
      return;
    }

    this.store
      .select(isAuthorized)
      .pipe(
        filter((authorized) => {
          if (authorized) {
            return true;
          }
          // check if user was logged out. If true, don't dispatch getAuthentication
          this.store.select(isLoggedOut).subscribe((userLoggedOut) => {
            const authStatus = this.cookie.get(this.AUTH_STATE_COOKIE);
            if (
              !userLoggedOut &&
              (authStatus === 'LOGGED_IN' || authStatus === 'IN_PROGRESS')
            ) {
              // we must try to fetch the User information only if the User is already logged in or if the data protection agreement was accepted
              this.store.dispatch(getAuthentication());
            }
          });
          return false;
        })
      )
      .subscribe(() => {
        this.router.navigate(['/home']).then();
      });
  }

  login() {
    this.authService.startAuthentication();
  }

  isAuthFailed(): boolean {
    return (
      this.isLoginFailed ||
      this.cookie.get(this.AUTH_STATE_COOKIE)?.startsWith('FAILED')
    );
  }

  getFailureMessageKey(): string {
    return this.authFailureCode ? 'login.' + this.authFailureCode : '';
  }

  /**
   * Used to initialize the login failure state for the following cases:
   * - when something went wrong on backend (having the AUTH_STATE_COOKIE cookie in the redirect)
   * - when something went wrong on backend for a direct call from UI (having the getAuthenticationFailure action)
   */
  private initLoginState() {
    // clear the possible old failure code
    this.authFailureCode = '';
    this.isLoginFailed = this.isAuthFailed();
    this.store.select(hasAuthError).subscribe((hasError) => {
      this.isLoginFailed = this.isLoginFailed || hasError;
      // init the failure reason when we receive it from backend
      this.initFailureReason();
    });

    this.initFailureReason();
  }

  private initFailureReason() {
    if (this.isLoginFailed) {
      this.authFailureCode = this.cookie.get(this.AUTH_STATE_COOKIE);
      this.cookie.delete(this.AUTH_STATE_COOKIE);
    }
  }
}
