<div class="wrapper">
  <h1>Datenschutzerklärung</h1>

  <div class="group section noline span3of4">
    <p>
      <br />
      Die Datenschutz-Grundverordnung (DSGVO) gilt gemäß Art. 2 Absatz 1 „für
      die ganz oder teilweise automatisierte Verarbeitung personenbezogener
      Daten, die in einem Dateisystem gespeichert sind oder gespeichert werden
      sollen."
    </p>
    <p>
      Der Betrieb einer Webseite führt in jedem Fall zur automatisierten
      Verarbeitung personenbezogener Daten. An dieser Stelle informieren wir Sie
      entsprechend unserer gesetzlichen Verpflichtung gemäß Art. 13 DSGVO.
    </p>
    <p class="c1">Informationen gemäß Art. 13 DSGVO</p>
    <p>
      <strong><br />Verantwortlicher für Online Antragsmanagement Plattform:<br/></strong>
      Niedersächsisches Ministerium für Bundes- und Europaangelegenheiten und
      Regionale Entwicklung<br />
      Osterstraße 40<br />
      30159 Hannover<br />
    </p>
    <p>
      <strong><br />Kontaktdaten des Datenschutzbeauftragten für Online Antragsmanagement
        Plattform:<br/></strong>
      Niedersächsisches Ministerium für Bundes- und Europaangelegenheiten und
      Regionale Entwicklung<br />
      Datenschutzbeauftragte/r<br />
      Osterstraße 40<br />
      30159 Hannover<br />
      E-Mail:
      <a href="mailtp:datenschutzbeauftragte@mb.niedersachsen.de"
        >datenschutzbeauftragte&#64;mb.niedersachsen.de</a
      >
    </p>
    <p>
      <strong><br />Verantwortlicher für die Richtlinien ZILE und LEADER:<br/></strong>
      Niedersächsisches Ministerium für Ernährung, Landwirtschaft und
      Verbraucherschutz<br />
      Calenberger Str. 2<br />
      30169 Hannover<br />
      Telefon: 0511/1200<br />
      Telefax: 0511/1202385<br />
      E-Mail:
      <a href="mailto:poststelle@ml.niedersachsen.de"
        >poststelle&#64;ml.niedersachsen.de</a
      >
    </p>
    <p>
      <strong><br />Kontaktdaten des Datenschutzbeauftragten für die Richtlinien ZILE und LEADER:<br/></strong>
      Niedersächsisches Ministerium für Ernährung, Landwirtschaft und
      Verbraucherschutz<br />
      Datenschutzbeauftragte/r<br />
      Calenberger Str. 2<br />
      E-Mail:
      <a href="mailto:datenschutz@ml.niedersachsen.de"
        >datenschutz&#64;ml.niedersachsen.de</a
      >
    </p>
    <p>
      Um Kommunikation im Internet zu ermöglichen, müssen Daten von dem Server,
      auf dem sie gespeichert sind, zu dem angeschlossenen Computer gelangen,
      der auf die Inhalte zugreifen möchte. Hierzu muss mit der Anfrage, die
      Daten abzurufen, auch mitgeteilt werden, an welchen Empfänger die
      Information vom Server übermittelt werden soll. Zu diesem Zwecke sind
      allen mit dem Internet verbundenen Geräten sog. IP-Adressen zugeordnet.
      Die Übermittlung der IP-Adresse erfolgt beim Aufruf einer Internetseite
      automatisch durch den Browser des Nutzers. Zu diesem Zweck werden die
      IP-Adressen der Nutzer, die die Webseite des Niedersächsischen
      Ministeriums für Ernährung, Landwirtschaft und Verbraucherschutz aufrufen,
      sowie die durch den Browser übermittelten Daten durch das Niedersächsische
      Ministerium für Ernährung, Landwirtschaft und Verbraucherschutz
      verarbeitet.
    </p>
    <p>
      Technische Fragen, die vom Nutzer über die E-Mail-Adresse <a href="mailto:OAMAN-Support@sla.niedersachsen.de"
    >OAMAN-Support&#64;sla.niedersachsen.de</a>  beim
      Servicezentrum Landentwicklung und Agrarförderung (SLA) eingehen, werden über ein Ticketsystem verarbeitet.
      Es werden nur Daten verarbeitet, die vom Absender der Anfrage selbst angegeben werden.
    </p>
    <p>
      Die Verarbeitung erfolgt zur Erfüllung der Aufgaben nach Art. 6 Abs. 1
      lit. c und lit.e in Verbindung mit Art. 57 DSGVO. Die Daten werden
      entsprechend der rechtlichen Vorgaben gespeichert.
    </p>
    <p class="c1">Einbindung Dienstleister</p>
    <p>
      Die Webseite wird durch Auftragsverarbeiter technisch betreut. Das
      bedeutet, dass alle personenbezogen Daten auch an diese übermittelt werden
      können. Bei den Auftragsverarbeitern handelt es sich um einen
      Host-Provider, dem SLA (Zurverfügungstellung der technischen Infrastruktur). Eine Übermittlung
      personenbezogener Daten, die bei der Nutzung der Webseite verarbeitet
      werden, an Dritte findet nicht statt.
    </p>
    <p class="c1">Ihre Rechte als betroffene Person</p>
    <p>
      <span class="c2">Recht auf Auskunft:</span><br />
      Es besteht ein Recht auf Auskunft seitens des Verantwortlichen über die
      erhobenen personenbezogenen Daten sowie auf Berichtigung unrichtiger
      Daten. Die Löschung der Daten kann im Rahmen des Art. 17 DSGVO beantragt
      werden, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt
      werden. Es besteht zudem das Recht auf Einschränkung der Verarbeitung,
      wenn eine der in Art. 18 DSGVO genannten Voraussetzungen vorliegt. Ein
      Recht auf Datenübertragbarkeit besteht in den Fällen des Art. 20 DSGVO.
    </p>
    <p>
      <span class="c2">Beschwerderecht bei einer Aufsichtsbehörde:</span><br />
      Es besteht das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn die
      Verarbeitung der betreffenden Daten Ihrer Ansicht nach gegen
      datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann bei
      der Niedersächsischen Landesbeauftragten für den Datenschutz geltend
      gemacht werden, die für das Land Niedersachsen zuständig ist.
    </p>
    <p>
      <span class="c2"
        >Die Beschwerdestelle des Landes Niedersachsen erreichen Sie:</span
      ><br />
    </p>
    <p>
      Die Landesbeauftragte für den Datenschutz Niedersachsen (LfD)<br />
      Brief: Postfach 221, 30002 Hannover<br />
      Besucher- und Lieferanschrift: Prinzenstraße 5, 30159 Hannover<br />
      Telefon: 0511 120-4500<br />
      Montag und Mittwoch: 09.00 - 12.00 Uhr<br />
      Donnerstag: 14.00 - 16.00 Uhr<br />
      Fax: 0511 120-4599<br />
      E-Mail:
      <a href="mailto:poststelle@lfd.niedersachsen.de"
        >poststelle&#64;lfd.niedersachsen.de</a
      >
    </p>
    <p>
      <span class="c2">Verpflichtung der Bereitstellung:</span><br />
      Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich noch
      vertraglich vorgeschrieben. Sie ist allerdings für die sachgerechte
      Bearbeitung Ihrer Eingabe erforderlich. Mögliche Folge der
      Nichtbereitstellung ist eine Einstellung der Bearbeitung.
    </p>
    <p>
      <span class="c2">Widerspruch:</span><br />
      Sie können der weiteren Verarbeitung Ihrer Daten jederzeit aus Gründen,
      die sich aus Ihrer besonderen Situation ergeben, im Rahmen der
      Voraussetzungen des Art. 21 DSGVO formlos per E-Mail an
      <a href="mailto:pressestelle@ml.niedersachsen.de"
        >pressestelle&#64;ml.niedersachsen.de</a
      >
      /
      <a href="mailto:pressestelle@mb.niedersachsen.de"
        >pressestelle&#64;mb.niedersachsen.de</a
      >
      widersprechen. Der Widerspruch führt zur Löschung der erhobenen
      Nutzerdaten.<br />
      <br />
    </p>
    <p class="c1">Automatisierte Entscheidungsfindung</p>
    <p>
      Es besteht keine automatisierte Entscheidungsfindung einschließlich
      Profiling gemäß Art. 22 DSGVO.<br />
      <br />
    </p>
  </div>
</div>
