<div class="contact">
  <h1 class="heading">Wir sind für Sie da</h1>
  <p class="opening-hours">
    Montag bis Donnerstag von 9.00 bis 15.30 Uhr, Freitag von 9.00 bis 12.00 Uhr
  </p>
  <div class="box">
    <div class="image-container">
      <img src="assets/wir-sind-fuer-sie-da.png" alt="contact" />
    </div>
    <div class="info">

      <div class="text-block">
        <p class="emphasized">Bei Fragen zu OAMAN ÄrL</p>
      </div>
      <div class="text-block">
        <p>
          <a class="mail" href="mailto:OAMAN-Support@sla.niedersachsen.de">
            OAMAN-Support&#64;sla.niedersachsen.de
          </a>
        </p>
      </div>

      <div class="text-block">
        <p class="emphasized">Bei Fragen zu ZILE3</p>
      </div>
      <div class="text-block">
        <p>
          <a class="mail" href="mailto:SLA-ZILE3@sla.niedersachsen.de">
            SLA-ZILE3&#64;sla.niedersachsen.de
          </a>
        </p>
      </div>

    </div>
  </div>
</div>
