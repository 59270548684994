<div class="wrapper">
  <div fxLayout="row wrap" fxLayout.lt-sm="column wrap" fxLayoutGap="3rem">
    <oaman-action-sheet
      icon="toc"
      text="{{ 'home.myProjects' | translate }}"
      [routerLink]="['/funding-projects', { contactType: 'assigned' }]"
    ></oaman-action-sheet>
    <oaman-action-sheet
      icon="toc"
      text="{{ 'home.myProjectsInRegion' | translate }}{{ authority$ | async }}"
      [routerLink]="['/funding-projects', { contactType: 'nobody_assigned' }]"
    ></oaman-action-sheet>
  </div>
</div>
