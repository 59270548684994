import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectUserState } from '../core/authentication/state/auth.selectors';
import { AppState } from '../core/state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  authority$ = new Observable<string>();

  constructor(private store: Store<AppState>) {}
  ngOnInit() {
    // select the current users authority from the user object in the store
    this.authority$ = this.store
      .select(selectUserState)
      .pipe(map((state) => (state ? state.authority?.title : '')));
  }
}
