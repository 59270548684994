import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { AppConfigService } from '../../config/app-config.service';
import { removeAuthObject } from '../state/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authIssuer = '';
  private client_id = '';
  private redirect_uri = '';
  private response_type = '';
  private scope = '';

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private env: AppConfigService,
    private cookie: CookieService,
    private http: HttpClient
  ) {
    this.authIssuer = this.env.getConfig().authIssuer;
    this.client_id = this.env.getConfig().authClientId;
    this.redirect_uri = this.env.getConfig().authRedirectUrl;
    this.response_type = this.env.getConfig().authResponseType;
    this.scope = this.env.getConfig().authScope;
  }

  startAuthentication() {
    // initiates the login flow with the oauth provider
    window.location.href = `${this.authIssuer}?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&scope=${this.scope}&response_type=${this.response_type}`;
  }

  logout() {
    let logoutUrl =
      this.env
        .getConfig()
        .backendApiUrl.replace(this.env.getConfig().backendApiPrefix, '') +
      '/oaman/logout';

    // performing backend driven logout
    this.http.get<any>(logoutUrl).subscribe({
      next: () => {
        this.logoutCallback();
      }
    });
  }

  private logoutCallback(): void {
    this.cookie.deleteAll();
    this.store.dispatch(removeAuthObject());
    this.router.navigate(['login']);
  }
}
