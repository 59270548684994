import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { isAdmin } from '../authentication/state/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AdminDashboardGuard {
  constructor(
    public router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let admin = false;
    this.store.select(isAdmin).subscribe((value) => {
      admin = value;
    });

    if (admin) {
      return true;
    } else {
      this.router
        .navigate(['/'])
        .then((_) => console.log('unauthorized, navigated to home'));
      return false;
    }
  }
}
