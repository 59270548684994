import { filter, map, switchMap } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { hasLoaded, isAuthorized } from '../state/auth.selectors';
import { Observable } from 'rxjs';
import { StateStorageService } from 'oaman-components';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private stateStorageService: StateStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(hasLoaded).pipe(
      filter((loaded) => loaded),
      switchMap(() => {
        return this.store.select(isAuthorized).pipe(
          map((authorized) => {
            if (!authorized) {
              // store the previous url to be able to reload the last state after the next successful login
              this.stateStorageService.storeUrl(state.url);
              this.router
                .navigate(['/login'])
                .then((_) => console.log('unauthorized, navigated to login'));
              return false;
            }

            return this.navigateToStoredUrl();
          })
        );
      })
    );
  }

  private navigateToStoredUrl(): boolean {
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
      // deny the current state and redirect to the old one
      return false;
    }

    // allow the current state
    return true;
  }
}
