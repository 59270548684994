import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule
} from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { TabsComponent } from './navigation/navbar/components/tabs/tabs.component';
import { ImpressumComponent } from './navigation/footer/components/impressum/impressum.component';
import { PrivacyStatementComponent } from './navigation/footer/components/privacy-statement/privacy-statement.component';
import { environment } from '../environments/environment';
import { NavbarComponent } from './navigation/navbar/navbar.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { ApiInterceptor } from './core/interceptors/ApiInterceptor';
import { authReducer } from './core/authentication/state/auth.reducers';
import { HttpLoaderFactory } from './core/translation/loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MenuComponent } from './navigation/navbar/components/menu/menu.component';
import { AboutComponent } from './navigation/navbar/components/menu/components/about/about.component';
import { ProfileComponent } from './navigation/navbar/components/menu/components/profile/profile.component';
import {
  GlobalErrorHandler,
  OamanComponentsModule,
  APP_CONFIG
} from 'oaman-components';
import { AppConfigService } from './core/config/app-config.service';
import { appInitializerFn } from './core/config/app-initializer';
import { AuthEffects } from './core/authentication/state/auth.effects';
import { CookieService } from 'ngx-cookie-service';
import { NgxWebstorageModule } from 'ngx-webstorage';

registerLocaleData(localeDe);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    TabsComponent,
    ImpressumComponent,
    PrivacyStatementComponent,
    NavbarComponent,
    FooterComponent,
    MenuComponent,
    AboutComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    StoreModule.forRoot({ authentication: authReducer }),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    OamanComponentsModule.forRoot({
      context: 'manage'
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'oaman',
      separator: '-',
      caseSensitive: true
    })
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    CookieService,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: APP_CONFIG,
      useValue: {
        context: 'manage'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
