import { User, MailSendingConfigDto } from 'oaman-components';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../config/app-config.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    private env: AppConfigService
  ) {}

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(
      `${this.env.getConfig().backendApiUrl}/users/current`
    );
  }

  getMailDeliveryConfig(
    identityProvider: string
  ): Observable<MailSendingConfigDto> {
    return this.http.get<MailSendingConfigDto>(
      `${this.env.getConfig().backendApiUrl}/users/account/${identityProvider}`
    );
  }
}
