import { AppState } from '../../state';
import { createSelector } from '@ngrx/store';
import { Roles } from '../../config/roles.constants';

export const authState = (state: AppState) => state.authentication;

export const isAuthorized = createSelector(
  authState,
  (state) => state.authorized
);

export const hasLoaded = createSelector(authState, (state) => state.hasLoaded);

export const isLoggedOut = createSelector(
  authState,
  (state) => state.loggedOut
);

export const hasAuthError = createSelector(authState, (state) => !!state.error);

export const selectUserState = createSelector(authState, (state) => state.user);

export const getCurrentUser = createSelector(authState, (state) => state.user);

export const isAdmin = createSelector(authState, (state) => {
  if (!state.user || !state.user.roles) {
    return false;
  }
  return state.user?.roles?.includes(Roles.ADMIN);
});
