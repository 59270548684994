<div class="container">
  <h1 fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    Online-Antragsmanagement für die Ämter für regionale Landesentwicklung
  </h1>
  <p>{{ 'home.infoText' | translate }}</p>
  <mat-divider></mat-divider>
  <h2
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
    style="margin-top: 1em"
  >
    Unterstützte Browser
  </h2>
  <p fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    Das OAMan ÄrL unterstützt die Browser Microsoft Edge oder Google Chrome.
  </p>
  <h2
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
    style="margin-top: 1em"
  >
    Anmeldung
  </h2>
  <p fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    Bitte melden Sie sich an.
  </p>
  <div fxLayoutAlign="center">
    <oaman-button
      class="login-button"
      id="login-button-nav"
      color="primary"
      aria-label="Login Button"
      (click)="login()"
    >
      {{ 'Anmelden' | uppercase }}
    </oaman-button>
  </div>
  <div *ngIf="isLoginFailed" fxLayoutAlign="center">
    <div class="login-error">
      <strong>{{ 'login.failedTitle' | translate }}</strong>
      <span class="login-error-description">{{
          getFailureMessageKey() | translate
        }}</span>
    </div>
  </div>
</div>
