import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserObjectObservableService } from 'oaman-components';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userObjectObservableService: UserObjectObservableService
  ) {}

  loadAuth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthentication),
      exhaustMap(() => {
        // check if the cookie has been set
        // by fetching the userinfo the token is validated
        return this.userService.fetchCurrentUser().pipe(
          map((user) => {
            // propagate the User to common components-module
            this.userObjectObservableService.publish(user);
            return AuthActions.getAuthenticationSuccess({
              user
            });
          }),
          catchError(() => {
            return of(AuthActions.getAuthenticationFailure());
          })
        );
      })
    );
  });
}
